/* desktop version */
.AR-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
    overflow: hidden;
}
.AR-header-wrapper {
    display: flex;
    flex-direction: column;
    font-size: .8vw;
    max-width: 60vw;
    color: #A8B7CE;
    text-align: justify;
}
.AR-content-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 60.6vw;
    gap: 1vw 1.7vw;
}
.AR-single-tab{
    display: flex;
    flex-direction: column;
    width: 16vw;
    font-size: .8vw;
    height: 7vw;
    color: #8896AB;
    border: 2px solid #ffffff3d;
    padding:.5vw 1vw;
    border-radius: 19px;
    transition: .5s ease;
    text-align: justify;
}
.AR-single-tab:hover{
    border: 2px solid #01a4f574;
}
.AR-single-tab>h5{
    color: #fff;
    font-size: 1vw;
    text-transform: capitalize;
}

/* mobile version */

.AR-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 13vw;
    overflow: hidden;
}
.AR-header-wrapper-mob{
    width: 90vw;
    display: flex;
    flex-direction: column;
}
.AR-header-wrapper-mob>p{
    font-size: 2.5vw;
    text-align: justify;
    color: #A8B7CE;
}
.AR-gradient-headline-wrapper-mob {
    display: flex;
    align-items: center;
    gap: 1.8vw;
}
.AR-gradient-headline-wrapper-mob>h3{
    font-size: 6vw!important;
}
.AR-content-mob {
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(0,-20%);
}
.AR-single-slide-mob {
    display: flex;
    align-items: flex-start;
    width: 85vw;
    height: 20vw;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: 3vw;
    color:#A8B7CE;
    padding:2vw;
    border: 2px solid #ffffff3d;
    border-radius: 19px;
    margin-bottom: 2vw;
    text-align: justify;
}
.AR-single-slide-mob>h5 {
    font-size: 5vw;
    color:#fff;
}

/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}