/* desktop version */

.services-container{
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    /* background-color: #000000eb; */
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-transform: capitalize;
}

.services-content-wrapper{
    display: flex;
    justify-content: space-around;
}
.services-content-left,.services-content-right{
    width: fit-content;
    max-width: 44vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}
.services-content-left>p,
.services-content-right>p{
    font-size: 1vw;
    font-weight: 300;
    background: linear-gradient(93.16deg, rgba(57, 80, 154, 0.44) -21.27%, rgba(57, 80, 154, 0) 130.39%);
    backdrop-filter: blur(11.5px);
    padding: 1vw 2vw;
    border-radius: 41px;
    border: 1px solid rgba(57, 80, 154, 0.84);
}

.services-content-left-tabs,.services-content-right-tabs{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: .5vw 0;
    gap:1vw;
}
.services-content-right-tabs {
    flex-direction: column;
    border: none;
    justify-content: flex-end;
    /* align-items: center; */
    padding-left: 10vw;
}
.services-content-left-tabs>p,.services-content-right-tabs>p{
    width: 14vw;
    font-size: .8vw;
    border-left: 1px solid var(--clr-white);
    padding: 1vw 0;
    padding-left: 2vw;
    cursor: pointer;
   
}
.services-scroll-tip-wrapper{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2vw
}
.services-scroll-tip-wrapper>p{
    font-size: .8vw;
}
.services-scroll-tip-arrow{
    display: flex;
    align-items: center;
    gap: 2vw;
}
.scroll-down-btn-services{
    align-self: flex-end;
    width: vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}
.scroll-down-btn-services>img {
    position: absolute;
    width: 4vw;
    z-index: -1;
}
.services-scroll-tip-left-line,.services-scroll-tip-right-line{
    width: 40vw;
    height: 2px;
    background: linear-gradient(93.16deg, rgba(57, 80, 154, 0.44) -21.27%, rgba(57, 80, 154, 0) 130.39%);
        backdrop-filter: blur(11.5px);
}
.services-scroll-tip-right-line{
    transform: rotate(180deg);
}


/* mobile version */
.services-container-mob{
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 10vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    /* background-color: #000000eb; */
    background-color: #000000eb;
    padding: 5vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-transform: capitalize;
}
.services-content-wrapper-mob{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:5vw;
}
.services-topics-mob{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 3.2vw;
}
.services-topics-mob>p{
    font-size: 3.7vw;
    font-weight: 500;
    background: linear-gradient(93.16deg, rgba(57, 80, 154, 0.44) -21.27%, rgba(57, 80, 154, 0) 130.39%);
    backdrop-filter: blur(11.5px);
    padding: 2.4vw 7.2vw;
    border-radius: 41px;
    border: 1px solid rgba(57, 80, 154, 0.84);
}
.services-content-mob{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:3vw;
}
.services-content-mob>p{
    font-size: 3.73vw;
    padding: 2vw 0vw 2vw 4vw;
    border-left: 1px solid #fff;
    width: fit-content;
}
.services-scroll-tip-wrapper-mob{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:5vw;
}
.services-scroll-tip-wrapper-mob>p{
    font-size: 2.6vw;
    font-weight: 400;
}
.services-scroll-tip-arrow-mob {
    display: flex;
    align-items: center;
    gap: 2vw;
}

.services-scroll-tip-arrow-mob>img {
    width: 15vw;
    cursor: pointer;
}
.services-scroll-tip-left-line-mob,
.services-scroll-tip-right-line-mob {
    width: 35vw;
    height: 2px;
    background: linear-gradient(93.16deg, rgba(57, 80, 154, 0.44) -21.27%, rgba(57, 80, 154, 0) 130.39%);
    backdrop-filter: blur(11.5px);
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}

