/* desktop version */
.chains-we-work-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
    overflow: hidden;
}
.chains-header-wrapper{
    width: 60.6vw;
}
.chains-header-wrapper>p{
    font-size: .9vw;
    color: #A8B7CE;
    text-align: justify;
}
.chains-we-work-content-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 60.6vw;
    gap: 1vw 1.7vw;
}
.chains-we-work-single-tab{
    display: flex;
    flex-direction: column;
    width: 19vw;
}
.chains-we-work-single-tab>h5{
    color: #fff;
    font-size: 1vw;
    /* text-transform: capitalize; */
}

.chains-we-work-circles {
    position: absolute;
    right: 10vw;
    bottom: -5vw;
    width: 20vw;
}

/* mobile version */

.chains-we-work-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 13vw;
    overflow: hidden;
}
.chains-gradient-headline-wrapper-mob {
    display: flex;
    align-items: center;
    gap: 1.8vw;
}
.chains-gradient-headline-wrapper-mob>h3{
    font-size: 6vw!important;
}
.chains-we-work-content-wrapper-mob {
    display: flex;
    flex-direction: column;
    transform: translate(0, -10%);
    flex-wrap: wrap;
    max-height: 70vw;
}
.chains-header-desc-mob{
    font-size: 3vw;
    color:#A8B7CE;
    text-align: justify;
}
.chains-we-work-single-tab-wrapper-mob{
    display: flex;
    gap: 3vw;
    height:10vw
}
.chains-we-work-single-tab-wrapper-mob>img{
    width: 7vw;
    height: 7vw;
}
.chains-we-work-single-tab-mob {
    display: flex;
    flex-direction: column;
    font-size: 2vw;
    color: #8896AB;
}

.chains-we-work-single-tab-mob>h5 {
    color: #fff;
    font-size: 3vw;
    /* text-transform: capitalize; */
}

.chains-we-work-single-tab-mob>p {
    text-align: justify;
}
.chains-we-work-circles-mob {
    position: absolute;
    left: -20vw;
    bottom: -15vw;
    width: 60vw;
}
.chains-line-decor-mob{
    height: calc(100% - 20vw);
    width: 1px;
    border-left: .5px solid #ffffff52;
    position: absolute;
    transform: translate(3.5vw,0);
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}