/* desktop version */
.team-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2vw;
    overflow: hidden;
}

.team-header {
    width: 60.6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.team-header>p{
    font-size: .9vw;
    width: 60%;
    text-align: center;
    color: #A8B7CE;
}

.team-content-wrapper {
    width: 60.6vw;
    display: flex;
    align-items: center;
    transform: translate(0, .5vw);
}

.team-single-slide {
    width: 13vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(0, 2vw);
    transition: .3s all ease;
    padding-bottom: 2vw;
}

.team-single-slide>img {
    width: 13vw;
    cursor: pointer;
    border: 1px solid #ffffff71;
    border-radius: 19px;
    padding: .5vw;
}
.team-single-slide>p{
    font-size: .9vw;
    transform: translate(.5vw,0);
    text-transform: capitalize;
}


/* .swiper-slide{
    padding-bottom: 20vw!important;
} */

.team-middle-slide{
    transform: translate(0, 0vw);
}
.team-first-slide {
    transform: rotate(-15deg) translate(2vw, 5vw);
}
.team-prev-slide {
    transform: rotate(-30deg) translate(0vw, 20vw);
    opacity: 0;
}
.team-next-slide {
    transform: rotate(15deg) translate(-2vw, 5vw);
}

.team-next2-slide {
    transform: rotate(30deg) translate(0vw, 20vw);
    opacity: 0;
}
.team-arrow-wrapper {
    position: absolute;
    justify-self: center;
    /* transform: translate(0, -5vw); */
    display: flex;
    width: 75vw;
    justify-content: space-between;
}

.team-arrow {
    width: 4vw;
    cursor: pointer;
}

/* mobile version */
.team-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 7vw;
    overflow: hidden;
}
.team-headline-mob{
    font-size: 8vw;
}
.team-arrow-wrapper-mob{
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}
.team-arrow-mob{
    width: 13vw;
}
.team-content-wrapper-mob {
    align-self: center;
    width: 70vw;
    display: flex;
    align-items: center;
}
.team-single-slide-mob {
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(0, 2vw);
    transition: .3s all ease;
    padding-bottom: 2vw;
}

.team-single-slide-mob>img {
    width: 65vw;
    cursor: pointer;
    border: 1px solid #ffffff71;
    border-radius: 19px;
    padding: 2vw;
}

.team-single-slide-mob>p {
    font-size: 4vw;
    /* transform: translate(.5vw, 0); */
    text-transform: capitalize;
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}