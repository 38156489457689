/* desktop version */

.metaverse-container{
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
    overflow: hidden;
}
.metaverse-headline-wrapper{
    width: 60.6vw;
    display: flex;
    align-items: center;
    gap: 1.5vw;
}
.metaverse-headline-wrapper>div{
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    background-color: var(--clr-white);
}

.metaverse-content-wrapper{
    width: 60.6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.metaverse-content-wrapper>p{
    width: 59%;
    font-size: .9vw;
    transform: translate(0, -5vw);
    text-align: justify;
    color: #A8B7CE;
}
.metaverse-content-wrapper>div{
    display: flex;
    flex-direction: column;
    gap: 2vw;
}
.metaverse-tab{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 17vw;
    padding: 1px;
    height: 10vw;
    border-radius: 10px;
    justify-content: center;
}
.metaverse-icon{
    width: 2.5vw;
    z-index: 4;
}
.metaverse-border{
    width: 17vw;
    position: absolute;
}
.metaverse-tab>p {
    z-index: 4;
    font-size: 1vw;
    font-weight: 700;
}
.metaverse-content-wrapper>div>:last-child{
    transform: translate(-5vw,0);
}
.metaverse-circles{
    position: absolute;
    align-self: flex-start;
    left: 25vw;
    bottom: -3vw;
    width: 20vw;
}
/* mobile version */
.metaverse-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 10vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 5vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    overflow: hidden;
}
.metaverse-headline-wrapper-mob {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 1.5vw;
    transform: translate(0, -10vw);
}
.mettaverse-circle-dec{
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 50%;
    background-color: #fff;
}
.metaverse-page-headline-mob{
    font-size: 5.5vw;
}
.metaverse-content-wrapper-mob{
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10vw;
    transform: translate(0, -25vw);
}
.metaverse-content-wrapper-mob>p{
    font-size: 3vw;
    transform: translate(0, -5vw);
    text-align: justify;
    color: #A8B7CE;
}
.metaverse-content-wrapper-mob>div{
    align-self: center;
    display: flex;
    gap: 10vw;
}
.metaverse-tab-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38vw;
    padding: 1px;
    height: 10vw;
    border-radius: 10px;
    justify-content: center;
}

.metaverse-icon-mob {
    width: 5vw;
    z-index: 4;
}

.metaverse-border-mob {
    width: 38vw;
    height: 30vw;
    position: absolute;
}

.metaverse-tab-mob>p {
    z-index: 4;
    font-size: 2.2vw;
    font-weight: 700;
}

.metaverse-content-wrapper-mob>div>:last-child {
    transform: translate(-5vw, 0);
}

.metaverse-circles-mob {
    position: absolute;
    align-self: flex-start;
    left: -15vw;
    bottom: -10vw;
    width: 70vw;
}

/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}