@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,100;1,300;1,400;1,500;1,600&display=swap');

*::before,
*::after,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}


:root {
  --clr-white: #fff;
  --clr-text: #A8B7CE;
  --clr-black: #000;
  --clr-gradient: linear-gradient(90deg, rgba(0, 51, 245, 1) 0%, rgba(114, 250, 253, 1) 100%);
  --font: 'Poppins',
    sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: inline-block;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: var(--font);
  background-color: var(--clr-black);
  animation: scrollbarHiiden .8s ;
  color: var(--clr-white);
  -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hidden{
  display:none!important;
}

.main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden!important;
  min-height:100vh;
}
.page-headline {
  font-size: 3.5vw;
  font-weight: 700;
}
.page-headline-mob{
  font-size: 11.7vw;
  font-weight: 700;
}
.gradient-headline-wrapper {
  display: flex;
  gap: 1vw;
  color: #fff;
}
.gradient-headline{
  background: -webkit-linear-gradient(46deg, rgba(1, 53, 245, 1) 0%, rgba(103, 231, 253, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.page-close-btn {
  align-self: flex-end;
  position: absolute;
  top: 7vw;
  width: 2vw;
  cursor: pointer;
  z-index: 4;
  transform: translate(-5vw,0);
}
@media only screen and (max-width:500px){
  .page-close-btn {
    align-self: flex-end;
    position: absolute;
    top: 20vw;
    width: 8vw;
    cursor: pointer;
    z-index: 4;
    transform: translate(-3vw,0);
  }
}

.scroll-up-btn{
  position: absolute;
  align-self: flex-end;
  width: 4vw;
  top: 11vw;
  transform: translate(-4vw, 0) rotate(180deg);
  cursor: pointer;
  display: flex;
    align-items: center;
    justify-content: center;
  z-index: 4;
}
.scroll-up-btn>img {
  position: absolute;
  width: 4vw;
  max-width: 70px;
  z-index: -1;
}
.scroll-down-btn {
  position: absolute;
  align-self: flex-end;
  width: 4vw;
  bottom: 8vw;
  transform: translate(-4vw, 0);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}
.hidden{
  display: none;
}
.scroll-down-btn>img{
  position: absolute;
  width: 4vw;
  max-width: 70px;
  z-index:-1;
}
.scroll-down-btn-small{
  position: absolute;
    align-self: flex-end;
    width: 3vw;
    bottom: 1.5vw;
    transform: translate(20%, 0) rotate(180deg);
    cursor: pointer;
}


.exit-up {
  animation: exit-up 1s forwards;
}

.appear-up {
  animation: appear-up 1s ease;
}

.appear-down {
  animation: appear-down 1s ease;
}

.exit-down {animation: exit-down 1s forwards;}
/* animation */

@keyframes appear-up {
  0% {
    transform: translate(0, 100%);
    opacity: 1;
  }

  100% {
    transform: translate(0, 0) !important;
    opacity: 1;
  }
}

@keyframes exit-up {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(0, -100%);
    opacity: 1;
  }
}
@keyframes exit-down {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(0, 100%);
    opacity: 1;
  }
}

@keyframes appear-down {
  0% {
    transform: translate(0, -100%);
    opacity: 1;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}


/* animation that hides scrollbar for 1s */

@keyframes scrollbarHiiden {
    0%{overflow-y: hidden;}
        99% {
          overflow-y: hidden;
        }
    100%{overflow-y: auto;}
}