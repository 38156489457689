/* desktop version */

.about-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2vw;
    overflow: hidden;
}

.about-header-wrapper{
    width: 60.6vw;
    display: flex;
    flex-direction: column;
    font-size: .7vw;
    color: #A8B7CE;
    text-align: justify;
    gap:.5vw;
}
.about-header-wrapper>h3{
    color: #fff;
    font-size: 3.5vw;
    font-weight: 700;
}
.about-content-wrapper{
    width: 60.6vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: .9vw;
    gap:13vw
}
.about-single-tab{
    width: 28%;
    display: flex;
    color: #A8B7CE;
    font-weight: 300;
    justify-content: space-between;
    /* text-transform: capitalize; */
}
.about-content-stacks{
    color: #A8B7CE;
    font-size: .9vw;
}
.about-single-tab>:last-child{
    font-weight: 800;
    color: #fff;
}
/* mobile version */
.about-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 7vw;
    overflow: hidden;
}
.about-header-wrapper-mob{
    align-self: center;
    width: 90vw;
    display: flex;
    flex-direction: column;
    font-size: 2.5vw;
    color: #A8B7CE;
    text-align: justify;
    gap:2vw;
}
.about-headline-mob{
    font-size: 10vw !important;
    color: #fff;
}
.about-content-wrapper-mob {
    align-self: flex-start;
    width: 90vw;
    display: flex;
    flex-direction: column;
    transform: translate(0, -30%);
}
.about-single-tab-mob {
    display: flex;
    color: #A8B7CE;
    font-weight: 300;
    justify-content: space-between;
}

.about-single-tab-mob>:last-child {
    font-weight: 800;
    color: #fff;
}

/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}