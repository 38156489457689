.blockchainIndustries-container{
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2vw;
    overflow: hidden;
}
.blockchainIndustries-headline-wrapper>:first-child{
    background: -webkit-linear-gradient(46deg, rgba(1, 53, 245, 1) 0%, rgba(103, 231, 253, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vw;
    margin-right: .8vw;
}
.blockchainIndustries-headline-wrapper{
    width: 60.6vw;
}
.blockchainIndustries-headline-wrapper>:last-child {
    font-size: 2.5vw;
}
.blockchainIndustries-content-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 60.6vw;
    gap: 1vw;
}
.blockchainIndustries-single-tab{
    width: 19vw;
}
.blockchainIndustries-single-tab>div{
    display: flex;
    align-items: center;
    padding-left: .3vw;
    gap: .5vw;
    text-transform: capitalize;
    font-weight: 700;
}
.blockchainIndustries-single-tab>div>img{
    width: 1.5vw;
}
.blockchainIndustries-single-tab>ul{
    font-size: .8vw;
    color: #A8B7CE;
    transform: translate(-1vw,0);
}

/* mobile version */

.blockchainIndustries-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 13vw;
    overflow: hidden;
}
.blockchainIndustries-headline-wrapper-mob>:first-child {
    background: -webkit-linear-gradient(46deg, rgba(1, 53, 245, 1) 0%, rgba(103, 231, 253, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 8vw;
    margin-right: .8vw;
}

.blockchainIndustries-headline-wrapper-mob {
    display: flex;
    flex-direction: column;
}

.blockchainIndustries-headline-wrapper-mob>:last-child {
    font-size: 4vw;
    font-weight: 400;
}
.blockchainIndustries-content-wrapper-mob{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    height: 70vw;
    overflow: scroll;
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}