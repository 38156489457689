.wm-container{
    position: absolute;
    width: 300px;
    height: 100px;
    left:calc(50% - 150px);
    padding: 1vw;
    background-color: rgb(21, 21, 21);
    transition: .3s ease;
    z-index: 6;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 1px;
        opacity: .8;
        background: linear-gradient(60deg, #0033F5 0%, #6CEFFD 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        animation-fill-mode: both;

        /** Mozilla Firefox */
        @-moz-document url-prefix() {
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask-composite: exclude;
        }
    }
}
.wm-close-btn{
    position: absolute;
    right: 2px;
    top:2px;
    width: 20px;
    cursor: pointer;
}