/* desktop version */

.defi-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
    overflow: hidden;
}

.defi-headline-wrapper{
    width: 60.6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
}
.defi-headline-wrapper>div{
    display: flex;
    flex-direction: column;
    font-size: 3vw;
    font-weight: 700;
}

.defi-headline-wrapper>div>:first-child{
    background: -webkit-linear-gradient(46deg, rgba(1, 53, 245, 1) 0%, rgba(103, 231, 253, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.defi-headline-wrapper>:last-child{
    width: 20vw;
    padding: 1vw;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.19);
    z-index: 3;
}
.defi-circles {
    width: 8vw;
    right: 23vw;
    position: absolute;
    z-index: 2;
    transform: translate(0, -4.5vw);
}
.defi-content-wrapper{
    width: 60.6vw;
    display: flex;
    justify-content: center;
    gap: 2.5vw;
    flex-wrap: wrap;
}
.defi-content-wrapper>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 14vw;
    gap:1.5vw
}
.defi-content-wrapper>div>p{
    font-size: .8vw;
    text-align: center;
}
.defi-content-num{
    display: flex;
    align-items: center;
    justify-content: center;
}
.defi-content-num>img{
    width: 2.5vw;
    position: absolute;
}

/* mobile version */

.defi-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 10vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
    overflow: hidden;
}

.defi-headline-wrapper-mob {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 3vw;
    width: 100%;
}

.defi-headline-wrapper-mob>div {
    align-self: flex-start;
    display: flex;
    font-size: 5.5vw;
    gap: 3vw;
    font-weight: 700;
}

.defi-headline-wrapper-mob>div>:first-child {
    background: -webkit-linear-gradient(46deg, rgba(1, 53, 245, 1) 0%, rgba(103, 231, 253, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
}

.defi-headline-wrapper-mob>:last-child {
    width: 90vw;
    border-radius: 17px;
    z-index: 3;
}
.defi-circles-mob {
    width: 30vw;
    right: 1vw;
    position: absolute;
    z-index: 2;
    transform: translate(0, -14vw);
}

.defi-content-wrapper-mob {
    align-self: center;
    width: 90vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5vw;
}

.defi-content-wrapper-mob>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40vw;
    gap:3vw;
}

.defi-content-wrapper-mob>div>p {
    font-size: 2.5vw;
    text-align: center;
}
.defi-content-num-mob {
    display: flex;
    align-items: center;
    justify-content: center;
}

.defi-content-num-mob>img {
    width: 8vw;
    position: absolute;
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}