/* desktop version style */

.blockchain-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
}
.blockchain-top-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 60.6vw;
}
.blockchain-description{
    font-weight: 300;
    font-size: 20px;
    color: #A8B7CE;
    font-size: 0.9vw;
    text-align: justify;
}
.blockchain-bot-wrapper{
    width: 60.6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blockchain-img,.blockchain-img-shadow{
    width: 12vw;
    margin-right: 6vw;
    z-index: 4;
}
.blockchain-img-shadow{
    position: absolute;
    z-index: 3;
    transform: translate(.5vw,.25vw);
    opacity: .1;
}
.blockchain-tabs{
    display: flex;
    flex-wrap: wrap;
    gap: 3vw 2vw;
    /* width: 45vw; */
    align-items: center;
}
.blockchain-tab{
    display: flex;
    gap: 1vw;
    align-items: center;
    width: 20vw;
    height: 1vw;
}
.blockchain-tab>div{
    padding: 2px;
    border-radius: 50px;
    border: 1px solid white;
}
.blockchain-tab>div>div{
    height: .5vw;
    width: .5vw;
    background-color: white;
    border-radius: 50%;
}

/* mobile version */

.blockchain-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 10vw);
    width: 89.6vw;
    top: calc(14.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 5vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
}

.blockchain-top-wrapper-mob {
    display: flex;
    flex-direction: column;
}
.gradient-headline-wrapper-mob{
    display: flex;
    gap: 1vw;
}
.gradient-headline-wrapper-mob>h3{
    font-size: 7vw;
}
.blockchain-description-mob{
    font-weight: 300;
    font-size: 2.9vw;
    color: #A8B7CE;
    text-align: justify;
}

.blockchain-bot-wrapper-mob {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 6vw;
    flex-direction: column;
    height: 65vw;
}
.blockchain-bot-img-wrapper-mob{
    position: relative;
    display: flex;
}
.blockchain-img-mob,
.blockchain-img-shadow-mob {
    width: 39vw;
    z-index: 4;
}
.blockchain-img-shadow-mob {
    position: absolute;
    z-index: 3;
    transform: translate(2vw, 1vw);
    opacity: .1;
}

.blockchain-tab-mob {
    display: flex;
    gap: 3vw;
    align-items: center;
    width: 40vw;
    height: 16.5vw;
}
.blockchain-tab-mob>p{
    font-size: 3vw;
}

.blockchain-tab-mob>div {
    padding: 4px;
    border-radius: 50px;
    border: 1px solid white;
}

.blockchain-tab-mob>div>div {
    height: 2vw;
    width: 2vw;
    background-color: white;
    border-radius: 50%;
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}