/* desktop version style */

.landing-container{
    width: 100vw;
    height: calc(100vh - 10.27vw - 2px);
    background-color: var(--clr-black);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* mobile */
.landing-container-mob {
    width: 100vw;
    min-height: calc(100vh - 29.2vw - 2px);
    background-color: var(--clr-black);
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: center;
}
.city{
    width: 100vw;
    
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}