.menu-container{
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 5;
    background-color: rgba(6, 7, 14, 1);;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:2vw;
}
.menu-back-btn{
    text-transform: uppercase;
    cursor: pointer;
    font-size: .8vw;
    opacity: 0.61;
}

.menu-nav-cont{
    display: flex;
    flex-direction: column;
}
.menu-single-nav-wrapper{
    width: 50vw;
    display: flex;
    flex-direction: column;
    padding: 1vw 0;
    color: var(--clr-white);
    text-transform: capitalize;
    height: 5vw;
    justify-content: space-between;
    cursor: pointer;
}
.menu-single-nav-info{
    display: flex;
    align-items: center;
    height: 4vw;
    width: 100%;
    justify-content: space-between;
}
.menu-singe-nav-line{
    min-height: 2px;
    max-height: 2px;
    width: 50vw;
    background: var(--clr-white);
    opacity: 0.21;
    position: absolute;
}
.menu-singe-nav-line-blue{
    position: absolute;
    min-height: 2px;
    max-height: 2px;
    background: linear-gradient(90deg, rgba(0, 51, 245, 1) 0%, rgba(114, 250, 253, 1) 100%);
    transition: .3s ease;
}
.menu-single-nav-wrapper:hover .menu-singe-nav-line-blue{
    animation: glow-line .7s forwards;
}


.menu-single-nav-info>p{
    font-size: 2.5vw;
    font-weight: 700;
}
.menu-single-nav-wrapper:hover .menu-single-nav-info>img{
    animation: nav-arrow .7s forwards;
}

/* mobile */
.menu-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 10vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 5;
    background-color: rgba(6, 7, 14, 1);
    padding: 5vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10vw;
}
.menu-back-btn-mob{
    text-transform: uppercase;
    cursor: pointer;
    font-size: 3.7vw;
    opacity: 0.61;
}
.menu-nav-cont-mob{
    display: flex;
    flex-direction: column;
    gap: 4vw;
}
.menu-single-nav-wrapper-mob{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2.1vw 0;
    color: var(--clr-white);
    text-transform: capitalize;
    height: 5vw;
    justify-content: space-between;
    cursor: pointer;
    
}
.menu-single-nav-info-mob{
    display: flex;
    align-items: center;
    height: 10vw;
    width: 100%;
    justify-content: space-between;
}
.menu-single-nav-info-mob>p{
    font-size: 5vw;
}

.menu-singe-nav-line-mob{
    min-height: 1px;
    max-height: 1px;
    width: 75vw;
    background: var(--clr-white);
    opacity: 0.21;
    position: absolute;
}



/* animation */
@keyframes glow-line {
    0% {
        width: 0vw;
    }

    100% {
        width: 50vw;
    }
}

@keyframes nav-arrow {
    0%{
        opacity: 0;
        transform: translate(-3vw,0);
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 1;
        transform: translate(0,0);
    }
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}