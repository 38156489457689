.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader-text {
    font-size: 1.2rem;
    margin-top: 10px;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}