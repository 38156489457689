/* desktop version style */

.footer-container{
    width: 92.1vw;
    height: 5.48vw;
    padding:  0 2.7vw 0 5.2vw;
    background-color: var(--clr-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--clr-text);
    animation: slideUp .7s ease;
    z-index: 5;
    transform: translate(0, 5px);
}
.footer-left-side{
    display: flex;
    align-items: center;
    gap: 1vw;
    color: var(--clr-white);
    font-size: .83vw;
    cursor: pointer;
}
.footer-contact{
    cursor: pointer;
}
.footer-line{
    height: 1.66vw;
    border-left:1px solid var(--clr-text) ;
}

.footer-sound{
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 0.74vw;
    cursor: pointer;
}

.footer-right-side{
    display: flex;
    align-items: center;
    gap: .83vw;
    
}
.footer-right-side>p{
    color: var(--clr-white);
    font-size: .83vw;
    text-transform: capitalize;
    cursor: pointer;
}
.footer-right-side>img{
    width: .93vw;
    cursor: pointer;
}
.footer-left-icon:hover{
    transform: scale(1.2);
    transition: .3s ease;
}
.menu-C-btn {
    font-size: .8vw;
    opacity: 0.61;
}
/* mobile version */

.footer-container-mob{
    width: 91.8vw;
    height: 14.6vw;
    padding: 0 4.2vw;
    background-color: var(--clr-black);
    display: flex;
    align-items: center;
    border-top: 1px solid var(--clr-text);
    animation: slideUp .7s ease;
    gap: 5.9vw  ;
    z-index: 5;
}
.footer-line-mob{
    height: 6.4vw;
    border-left: 1px solid var(--clr-text);
    width: 1px;
}

.footer-left-icon-mob{
    height: 5.9vw;
}

.menu-C-btn-mob {
    font-size: 3.2vw;
    opacity: .4;
}
/* animation */

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translate(0);
    }
}


/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}