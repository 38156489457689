/* desktop version */

.contact-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2vw;
    overflow: hidden;
}

.contact-header-wrapper{
    width: 60.6vw;
    display: flex;
    flex-direction: column;
    font-size: .7vw;
    color: #A8B7CE;
    text-align: justify;
    gap:.5vw;
}
.contact-header-wrapper>h3{
    color: #fff;
    font-size: 3.5vw;
    font-weight: 700;
}
.contact-content-wrapper{
    width: 60.6vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: .9vw;
    gap:13vw
}

.contact-form{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.contact-single-input{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 20vw;
    height: 2vw;
    border-radius: 10px;
    z-index: 1;
    padding: 1vw;
    background-color: rgba(128, 128, 128, 0.112);
    transition: .3s ease;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 1px;
        opacity: .8;
        background: rgba(128, 128, 128, 0.223);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        animation-fill-mode: both;
        /** Mozilla Firefox */
        @-moz-document url-prefix() {
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask-composite: exclude;
        }
    }
}
.contact-textarea{
    height: fit-content;
}
.contact-textarea>textarea{
    z-index: 2;
    resize: none;
    border: none;
    outline: none;
    background: none;
    color: #fff;
    height: 5vw;
    font-size: .8vw;
}
.contact-single-input>label{
    font-weight: 300;
    font-size: .8vw;
    
}
.contact-single-input>input{
    z-index: 2;
    border: none;
    outline: none;
    background:none;
    color: #fff;
    height:2vw;
    font-size: .8vw;
}
.contact-single-input-active{
    &::before {
        background: linear-gradient(60deg, #0033F5 0%, #6CEFFD 100%);
    }
}
.contact-btns-wrapper{
    width: 20vw;
    display: flex;
    justify-content: space-between;
}
.contact-submit-btn{
    height: 2.3vw;
    background: linear-gradient(60deg, #0033F5 0%, #6CEFFD 100%);
    border: none;
    width: 90%;
    border-radius: 10px;
    color: #fff;
    font-size: 1vw;
    cursor: pointer;
}

/* mobile version */
.contact-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 7vw;
    overflow: hidden;
}
.contact-header-wrapper-mob{
    align-self: center;
    width: 90vw;
    display: flex;
    flex-direction: column;
    font-size: 2.5vw;
    color: #A8B7CE;
    text-align: justify;
    gap:2vw;
}
.contact-headline-mob{
    font-size: 10vw !important;
    color: #fff;
}
.contact-content-wrapper-mob {
    align-self: flex-start;
    width: 90vw;
    display: flex;
    justify-content: center;
    transform: translate(0,-20%);
}

.contact-form-mob {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.contact-single-input-mob {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 60vw;
    height: 8vw;
    border-radius: 10px;
    z-index: 1;
    padding: 3vw;
    background-color: rgba(128, 128, 128, 0.112);
    transition: .3s ease;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 1px;
        opacity: .8;
        background: rgba(128, 128, 128, 0.223);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        animation-fill-mode: both;

        /** Mozilla Firefox */
        @-moz-document url-prefix() {
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask-composite: exclude;
        }
    }
}

.contact-textarea-mob {
    height: fit-content;
}

.contact-textarea-mob>textarea {
    z-index: 2;
    resize: none;
    border: none;
    outline: none;
    background: none;
    color: #fff;
    height: 25vw;
    font-size: 3vw;
}

.contact-single-input-mob>label {
    font-weight: 300;
    font-size: 3vw;

}

.contact-single-input-mob>input {
    z-index: 2;
    border: none;
    outline: none;
    background: none;
    color: #fff;
    height: 5vw;
    font-size: 3vw;
}

.contact-single-input-active-mob {
    &::before {
        background: linear-gradient(60deg, #0033F5 0%, #6CEFFD 100%);
    }
}

.contact-btns-wrapper-mob {
    width: 60vw;
    display: flex;
    justify-content: space-between;
}

.contact-submit-btn-mob {
    height: 7vw;
    background: linear-gradient(60deg, #0033F5 0%, #6CEFFD 100%);
    border: none;
    width: 90%;
    border-radius: 10px;
    color: #fff;
    font-size: 4vw;
    cursor: pointer;
}

/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}