/* desktop version */
.blockchain-projects-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2vw;
    overflow: hidden;
}

.blockchain-projects-header{
    width: 60.6vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blockchain-projects-content-wrapper{
    width: 60.6vw;
    display: flex;
    align-items: center;
}
.blockchain-projects-single-slide{
    width: 15vw;
    display: flex;
    justify-content: center;

}
.blockchain-projects-single-slide>img{
    width: 15vw;
    height: 15vw;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
}
.blockchain-projects-single-slide-desc{
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: .6vw;
    bottom: 0;
    background: rgba(0, 0, 0, 0.67);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
    height: 2vw;
    width: 12vw;
    padding: .5vw;
    transform: translate(0, -1vw);
    backdrop-filter: blur(9px);
    transition: .6s ease;
    cursor: pointer;
    font-weight: 600;
    gap:1.5vw;
}
.blockchain-projects-single-slide:hover>.blockchain-projects-single-slide-desc{
    background: rgba(255, 255, 255, 0.67);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: #000;
}
.blockchain-project-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blockchain-projects-single-slide-desc>div{
    position: absolute;
    bottom: .1vw;
    display: flex;
    gap: .3vw;
    color: #ffffff75;
    align-items: center;
    /* transform: translate(0,-.9vw); */
    font-size: .5vw;
    transition: .6s ease;
}
.blockchain-projects-single-slide:hover>.blockchain-projects-single-slide-desc>div{
    color: #00000075;
}
.blockchain-projects-single-slide-desc>div>img{
    width: .5vw;
}
.blockchain-projects-arrow-wrapper{
    position: absolute;
    /* top:10vw; */
    justify-self: center;
    transform: translate(0,-5vw);
    right: 20vw;
    display: flex;
}
.blockchain-projects-arrow{
    width: 3.5vw;
    cursor: pointer;
}

/* modal style */

.pv-container{
    width: 100vw;
    height: calc(100vh - 5vw);
    top:50%;
    transform: translate(0,-50%);
    position:absolute;
    z-index: 8;
    background: #000;
    display: flex;
    justify-content: center;
    padding: 5vw 0;
}

.pv-page-close-btn{
    position: absolute;
    right:3vw;
    width: 2.5vw;
    top:5vw;
    cursor: pointer;
}
.pv-content-wrapper{
    display: flex;
    flex-direction: column;
    width: 64vw;
    gap: 2vw;
    height: 100%;
    overflow-y: scroll;
    padding-right: 2vw;
}
/* Target the scrollbar */
.pv-content-wrapper::-webkit-scrollbar {
    width: 8px;
    /* Adjust the width of the scrollbar */
}

/* Track */
.pv-content-wrapper::-webkit-scrollbar-track {
    background: transparent;
    /* Make the track transparent */
}

/* Handle */
.pv-content-wrapper::-webkit-scrollbar-thumb {
    background: linear-gradient(46deg, rgba(103, 231, 253, .7) 0%, rgba(1, 53, 245, .7) 60%);
    /* Linear gradient for the scrollbar thumb */
    border-radius: 50px;
    /* Add rounded corners to the scrollbar handle */
}

/* Handle on hover */
.pv-content-wrapper::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(46deg, rgba(103, 231, 253, .7) 0%, rgba(1, 53, 245, .7) 60%);
    /* Linear gradient for the scrollbar thumb on hover */
}
.pv-section-text{
    color:#A8B7CE;
    margin-top: .5vw;
    text-align: justify;
}
.pv-section-headline {
    margin-bottom: .5vw;
}
.pv-header{
    width: 63vw;
    display: flex;
    gap:.5vw;
    flex-wrap:wrap;
}
.pv-header>h3{
    font-size: 1.5vw!important;
}
@media only screen and (max-width:500px){
    .pv-container {
        height: calc(100vh - 5vh);
        padding: 5vh 0;
        margin-top: 5vw;
    }
    .pv-page-close-btn {
        right: 2vw;
        width: 2.5vh;
        top: 5vh;
    }
    .pv-content-wrapper {
        width: 82vw;
        gap: 2vh;
        /* height: 100%; */
        height: 95%;
        overflow-y: scroll;
        padding-right: 2vw;
    }
    .pv-header {
        width: 80vw;
    }
    .pv-header>h3 {
        font-size: 18px !important;
    }
    .pv-content-wrapper::-webkit-scrollbar {
        width: 3px;
        /* Adjust the width of the scrollbar */
    }
    .pv-section-text {
        margin-top: 1vh;
        font-size: 12px;
    }

    .pv-section-headline {
        margin-bottom: 1vh;
        font-size: 14px;
    }
}
/* mobile version */

.blockchain-projects-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 13vw;
    overflow: hidden;
}
.blockchain-projects-header-mob {
    align-self: flex-start;
    font-size: 8vw;
}
.blockchain-projects-arrow-wrapper-mob {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    margin-top: 10vw;
}
.blockchain-projects-arrow-mob {
    width: 13vw;
}
.blockchain-projects-content-wrapper-mob {
    width: 90vw;
    display: flex;
    align-items: center;
}
.blockchain-projects-single-slide-mob {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blockchain-projects-single-slide-mob>img {
    width: 80vw;
    height: 80vw;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
}

.blockchain-projects-single-slide-desc-mob {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 3vw;
    bottom: 0;
    background: rgba(0, 0, 0, 0.67);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
    height: 9vw;
    width: 69vw;
    padding: 3vw 3vw;
    transform: translate(0, -1vw);
    backdrop-filter: blur(9px);
    transition: .6s ease;
    font-weight: 400;
    gap:3vw;
}
.blockchain-projects-single-slide-desc-mob>div {
    display: flex;
    gap: 2vw;
    color: #ffffff75;
    align-items: center;
    transform: translate(0, -2vw);
    font-size: 2.5vw;
    transition: .6s ease;
}

.blockchain-projects-single-slide-desc-mob>div>img {
    width: .5vw;
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}