/* desktop version */
.blockchain-success-stories-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 2vw;
}
.blockchain-success-stories-content{
    width: 60.6vw;
    display: flex;
    align-items: center;
    transform: translate(0, -20%);
}
.blockchain-success-stories-single-slide{
    display: flex;
    align-items: center;
    font-size: .9vw;
    width: 59vw;
    justify-content: space-evenly;
    cursor: pointer;
}
.blockchain-success-stories-slide-person{
    display: flex;
    align-items: center;
    gap: 1vw;
}
.blockchain-success-stories-slide-person>div{
    display: flex;
    flex-direction: column;
}
.blockchain-success-stories-slide-person>div>:first-child {
    transform: translate(0, .8vw);
    font-size: 1vw;
}
.blockchain-success-stories-slide-person>div>:last-child {
    transform: translate(0, -.8vw);
    font-size: .8vw;
    color: #8896AB;
}
.blockchain-success-stories-slide-person>img{
    width: 5vw;
}
.blockchain-success-stories-slide-story{
    width: 50%;
    font-size: 1vw;
    font-weight: 700;
    display: flex;
    text-align: center;
}
.blockchain-success-stories-slide-story>:first-child{
    align-self: center;
    position: absolute;
    width: 4vw;
    transform: translate(0, -1.6vw);
}
.blockchain-success-stories-slide-story>:last-child {
    align-self: center;
    transform: translate(26vw,1.6vw);
    position: absolute;
    width: 4vw;
}
.blockchain-success-stories-left-arrow{
    position: absolute;
    width: 3.5vw;
    transform: translate(-4vw);
    cursor: pointer;
}
.blockchain-success-stories-right-arrow{
    position: absolute;
    width: 3.5vw;
    transform: translate(61vw);
    cursor: pointer;
}
/* mobile version */

.blockchain-success-stories-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 13vw;
    overflow: hidden;
}
.success-stories-headline-mob{
    font-size: 8vw;
    font-weight: 900;
}
.blockchain-success-stories-content-mob {
    align-self: center;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blockchain-success-stories-arrows-wrapper{
    margin-top: 10vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 2vw;
}
.blockchain-success-stories-arrows-wrapper>img{
    width: 13vw;
}
.blockchain-success-stories-single-slide-mob {
    display: flex;
    align-items: center;
    font-size: .9vw;
    width: 90vw;
    justify-content: space-evenly;
    cursor: pointer;
    flex-direction: column;
}
.blockchain-success-stories-slide-person-mob {
    display: flex;
    align-items: center;
    gap: 1vw;
    flex-direction: column;
}

.blockchain-success-stories-slide-person-mob>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    
}

.blockchain-success-stories-slide-person-mob>div>:first-child {
    font-size: 3.5vw;
    margin: 0;
}

.blockchain-success-stories-slide-person-mob>div>:last-child {
    font-size: 3vw;
    margin: 0;
    color: #8896AB;
}

.blockchain-success-stories-slide-person-mob>img {
    width: 40vw;
}
.blockchain-success-stories-slide-story-mob {
    width: 70%;
    font-size: 3vw;
    font-weight: 700;
    display: flex;
    text-align: center;
}

.blockchain-success-stories-slide-story-mob>:first-child {
    align-self: center;
    position: absolute;
    width: 15vw;
    transform: translate(-10vw, -6vw);
}

.blockchain-success-stories-slide-story-mob>:last-child {
    align-self: center;
    transform: translate(53vw, 5vw);
    position: absolute;
    width: 15vw;
}

/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}