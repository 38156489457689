/* desltop version */

.crypto-container {
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 4vw;
    overflow: hidden;
}

.crypto-headline-wrapper{
    align-self: center;
    display: flex;
    flex-direction: column;
    
}
.crypto-headline-wrapper>.gradient-headline-wrapper{
    transform: translate(-11.8vw, -5vw);
}
.crypto-desc{
    width: 60vw;
    font-size: .9vw;
    color: #A8B7CE;
    text-align: justify;
    min-height: 4.2vw;
    transition: .7s ease;
    position: absolute;
    transform: translate(-11.5vw, 0);
}
.opacity-one{
    opacity: 1;
}
.crypto-content-wrapper{
    width: 60.6vw;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4vw;
    transform: translate(-2.5vw,0);
}
.crypto-decor-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.crypto-decor-wrapper>img{
    position: absolute;
}
.crypto-white-ball-top{
    width: .5vw;
    height: .5vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(.1vw, -5.6vw);
}
.crypto-white-ball-bot {
    width: .5vw;
    height: .5vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(.1vw, 5.6vw);
}
.crypto-white-ball-right {
    width: .5vw;
    height: .5vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(8.5vw, 0);
}
.crypto-white-ball-left {
    width: .5vw;
    height: .5vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(-8.5vw, 0);
}
.crypto-decor-6{
    width: 17vw;
}
.crypto-decor-5 {
    width: 16vw;
}
.crypto-decor-4 {
    width: 15vw;
}
.crypto-decor-3 {
    width: 13vw;
}
.crypto-decor-2 {
    width: 4vw;
    transform: translate(0, .8vw);
}
.crypto-decor-1 {
    width: 5vw;
}
.opacity-zero{
    opacity: 0;
}
.crypto-decor-7-top{
    width: 1.7vw;
    transform: translate(.1vw, -5.6vw);
}
.crypto-decor-7-bot {
    width: 1.7vw;
    transform: translate(.1vw, 5.6vw);
}
.crypto-decor-7-right {
    width: 1.7vw;
    transform: translate(8.5vw, 0);
}
.crypto-decor-7-left {
    width: 1.7vw;
    transform: translate(-8.5vw, 0);
}

.crypto-content-wrapper {
    width: 60.6vw;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4vw;
    transform: translate(-2.5vw, 0);
}

.crypto-content-wrapper>p{
    justify-self: center;
    position: absolute;
    font-weight: 700;
    font-size: .9vw;
    cursor: pointer;
}
.crypto-tab-top{
    transform: translate(0, -8vw);
}
.crypto-tab-bot {
    transform: translate(0, 8vw);
}
.crypto-tab-left {
    transform: translate(-16vw,0);
    width: 4vw;
}
.crypto-tab-right {
    transform: translate(16vw, 0);
    width: 9vw;
}

/* mobile version */

.crypto-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 10vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 5vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 25vw;
    overflow: hidden;
}
.crypto-headline-wrapper-mob {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 25vw;

}
.crypto-page-headline-mob{
    font-size: 7vw;
}
.crypto-desc-mob{
    width: 80vw;
    font-size: 2.5vw;
    color: #A8B7CE;
    text-align: justify;
    min-height: 4.2vw;
    transition: .7s ease;
    position: absolute;
    transform: translate(0, 10vw);
}
.crypto-decor-wrapper-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.crypto-decor-wrapper-mob>img {
    position: absolute;
}
.crypto-decor-1-mob {
    width: 20vw;
}
.crypto-decor-6-mob {
    width: 68vw;
}

.crypto-decor-5-mob {
    width: 64vw;
}

.crypto-decor-4-mob {
    width: 60vw;
}

.crypto-decor-3-mob {
    width: 52vw;
}

.crypto-decor-2-mob {
    width: 16vw;
    transform: translate(0, .8vw);
}

.crypto-content-wrapper-mob {
    width: 60.6vw;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4vw;
    transform: translate(-2.5vw, 0);
    margin-bottom: 35vw;
}



.crypto-decor-7-top-mob {
    width: 7vw;
    transform: translate(.5vw, -22vw);
}

.crypto-decor-7-bot-mob {
    width: 7vw;
    transform: translate(.5vw, 22vw);
}

.crypto-decor-7-right-mob {
    width: 7vw;
    transform: translate(33.5vw, 0);
}

.crypto-decor-7-left-mob {
    width: 7vw;
    transform: translate(-33.5vw, 0);
}
.crypto-content-wrapper-mob>p {
    justify-self: center;
    position: absolute;
    font-weight: 700;
    font-size: 3vw;
    cursor: pointer;
    text-align: center;
}
.crypto-tab-top-mob {
    transform: translate(0, -28vw);
    z-index: 10;
}

.crypto-tab-bot-mob {
    transform: translate(0, 28vw);
    z-index: 10;
}

.crypto-tab-left-mob {
    transform: translate(-35vw, -8vw);
    width: 20vw;
    z-index: 10;
}

.crypto-tab-right-mob {
    transform: translate(38vw, -8vw);
    width: 30vw;
    z-index: 10;
}

.crypto-white-ball-top-mob {
    width: 2.3vw;
    height: 2.4vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(.5vw, -22.1vw);
}

.crypto-white-ball-bot-mob {
    width: 2.3vw;
    height: 2.4vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(.1vw, 21.9vw);
}

.crypto-white-ball-right-mob {
    width: 2.3vw;
    height: 2.4vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(33.5vw, 0vw);
}

.crypto-white-ball-left-mob {
    width: 2.3vw;
    height: 2.4vw;
    background-color: rgb(195, 195, 195);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    transform: translate(-33.5vw, 0);
}
/* decor animation */

.animated-crypto-decor-1 {
    animation: crypto-decor-one 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-decor-2 {
    animation: crypto-decor-two 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-decor-3 {
    animation: crypto-decor-three 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-decor-4 {
    animation: crypto-decor-four 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-decor-5 {
    animation: crypto-decor-five 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-decor-6 {
    animation: crypto-decor-six 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-decor-7-top {
    animation: crypto-decor-seven-top 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-decor-7-bot {
    animation: crypto-decor-seven-bot 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-decor-7-left {
    animation: crypto-decor-seven-left 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-decor-7-right {
    animation: crypto-decor-seven-right 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-tab-bot {
    animation: crypto-tab-bot 1s forwards;
    animation-delay: 1s;
}
.animated-crypto-tab-top {
    animation: crypto-tab-top 1s forwards;
    animation-delay: 1s;
}
.animated-crypto-tab-left {
    animation: crypto-tab-left 1s forwards;
    animation-delay: 1s;
}
.animated-crypto-tab-right {
    animation: crypto-tab-right 1s forwards;
    animation-delay: 1s;
}
/* mob animation */
.animated-crypto-decor-7-top-mob {
    animation: crypto-decor-seven-top-mob 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-decor-7-bot-mob {
    animation: crypto-decor-seven-bot-mob 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-decor-7-left-mob {
    animation: crypto-decor-seven-left-mob 1s forwards;
    animation-delay: 2s;
}

.animated-crypto-decor-7-right-mob {
    animation: crypto-decor-seven-right-mob 1s forwards;
    animation-delay: 2s;
}
.animated-crypto-tab-bot-mob {
    animation: crypto-tab-bot-mob 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-tab-top-mob {
    animation: crypto-tab-top-mob 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-tab-left-mob {
    animation: crypto-tab-left-mob 1s forwards;
    animation-delay: 1s;
}

.animated-crypto-tab-right-mob {
    animation: crypto-tab-right-mob 1s forwards;
    animation-delay: 1s;
}

@keyframes crypto-decor-one {
    0% {
        opacity: 0;
        transform: translate(0, 1vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes crypto-decor-two {
    0% {
        opacity: 0;
        transform: translate(0, 3vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, .8vw);
    }
}

@keyframes crypto-decor-three {
    0% {
        opacity: 0;
        transform: translate(0, 2vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes crypto-decor-four {
    0% {
        opacity: 0;
        transform: translate(0, 2vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes crypto-decor-five {
    0% {
        opacity: 0;
        transform: translate(0, 3vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes crypto-decor-six {
    0% {
        opacity: 0;
        transform: translate(0, 4vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}


@keyframes crypto-decor-seven-top {
    0% {
        opacity: 0;
        transform: translate(.1vw, -5.6vw) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(.1vw, -5.6vw) rotate(360deg) scale(1);
    }
}
@keyframes crypto-decor-seven-top-mob{
    0% {
        opacity: 0;
        transform: translate(.1vw, -22vw) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(.5vw, -22vw) rotate(360deg) scale(1);
    }
}


@keyframes crypto-decor-seven-bot {
    0% {
        opacity: 0;
        transform: translate(.1vw, 5.6vw) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(.1vw, 5.6vw) rotate(360deg) scale(1);
    }
}
@keyframes crypto-decor-seven-bot-mob {
    0% {
        opacity: 0;
        transform: translate(.1vw, 22vw) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(.1vw, 22vw) rotate(360deg) scale(1);
    }
}

@keyframes crypto-decor-seven-right {
    0% {
        opacity: 0;
        transform: translate(8.5vw, 0) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(8.5vw, 0) rotate(360deg) scale(1);
    }
}
@keyframes crypto-decor-seven-right-mob {
    0% {
        opacity: 0;
        transform: translate(33.5vw, 0) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(33.5vw, 0) rotate(360deg) scale(1);
    }
}

@keyframes crypto-decor-seven-left {
    0% {
        opacity: 0;
        transform: translate(-8.5vw, 0) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(-8.5vw, 0) rotate(360deg) scale(1);
    }
}
@keyframes crypto-decor-seven-left-mob {
    0% {
        opacity: 0;
        transform: translate(-33.5vw, 0) rotate(30deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(-33.5vw, 0) rotate(360deg) scale(1);
    }
}

@keyframes crypto-tab-bot {
    0% {
        opacity: 0;
        transform: translate(0, 18vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 8vw);
    }
}

@keyframes crypto-tab-top {
    0% {
        opacity: 0;
        transform: translate(0, 2vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, -8vw);
    }
}

@keyframes crypto-tab-left {
    0% {
        opacity: 0;
        transform: translate(-16vw, 10vw);
    }

    100% {
        opacity: 1;
        transform: translate(-16vw, 0);
    }
}
@keyframes crypto-tab-right {
    0% {
        opacity: 0;
        transform: translate(16vw, 10vw);
    }

    100% {
        opacity: 1;
        transform: translate(16vw, 0);
    }
}
@keyframes crypto-tab-bot-mob {
    0% {
        opacity: 0;
        transform: translate(0, 18vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, 28vw);
    }
}

@keyframes crypto-tab-top-mob {
    0% {
        opacity: 0;
        transform: translate(0, -18vw);
    }

    100% {
        opacity: 1;
        transform: translate(0, -28vw);
    }
}

@keyframes crypto-tab-left-mob {
    0% {
        opacity: 0;
        transform: translate(-28vw, -5vw);
    }

    100% {
        opacity: 1;
        transform: translate(-35vw, -8vw);
    }
}

@keyframes crypto-tab-right-mob {
    0% {
        opacity: 0;
        transform: translate(29vw, -5vw);
    }

    100% {
        opacity: 1;
        transform: translate(38vw, -8vw);
    }
}

@keyframes fade-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

/* animation end */


/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}