/* desktop version style */

.header-container{
    width: 92.1vw;
    height: 4.79vw;
    padding:  0 2.7vw 0 5.2vw;
    background-color: var(--clr-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--clr-text);
    animation: slideDown .7s ease;
    z-index: 7;
}
.header-logo{
    color: var(--clr-white);
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1vw;
}
.header-logo>span{
    color: var(--clr-white);
    text-transform: capitalize;
    font-size: 1vw;
    font-weight: 400;
}
.header-navigation{
    display: flex;
    align-items: center;
    gap: 2.7vw;
    
}
.header-navigation>p{
    cursor: pointer;
    color: var(--clr-white);
    text-transform: capitalize;
    font-size: 0.83vw;
}
.header-navigation>p>img{
    width: 1.42vw;
    margin-right: .9vw;
    transform: translate(0, 20%);
}

.open-menu>img{
    width: 1.39vw!important;
}
.open-menu{
    transform: translate(0, -10%)!important;
}


/* mobile version */

.header-container-mob{
    width: 91.8vw;
    height: 14.6vw;
    background: var(--clr-black);
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.2vw;
    border-bottom: 1px solid var(--clr-text);
    animation: slideDown .7s ease;
    z-index: 5;
}
.header-logo-mob {
    color: var(--clr-white);
    text-transform: capitalize;
    font-weight: 600;
    font-size: 3.4vw;
}

.header-logo-mob>span {
    color: var(--clr-white);
    text-transform: capitalize;
    font-size: 3.4vw;
    font-weight: 400;
}

.header-container-mob>img{
    width: 5.3vw;
}

/* animation */

@keyframes slideDown {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translate(0);
    }
}

/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}