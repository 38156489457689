/* desktop version */

.smart-contract-audit-container{
    position: absolute;
    height: calc(100vh - 12.27vw - 2px);
    width: 89.6vw;
    top: calc(4.79vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* text-transform: capitalize; */
    gap: 2vw;
    overflow: hidden;
}
.smart-contract-audit-header{
    width: 60.6vw;
}
.smart-contract-audit-content-wrapper{
    width: 60.6vw;
    display: flex;
    gap: 3vw;
    font-size: .9vw;
    align-items: center;
    color: #A8B7CE;
    transform: translate(0, -10%);
}
.smart-contract-audit-content-wrapper>div{
    display: flex;
    flex-direction: column;
    gap:1vw;
}
.smart-contract-audit-content-wrapper>img{
    width: 25vw;
}
.smart-contract-audit-description,.consulting-description{
    text-align: justify;
    text-transform: none;
}

/* mobile version */

.smart-contract-audit-container-mob {
    position: absolute;
    height: calc(100vh - 14.6vw - 14.6vw - 2vw);
    width: 89.6vw;
    top: calc(14.6vw + 1px);
    z-index: 3;
    background-color: #000000eb;
    padding: 1vw 5.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-transform: capitalize;
    gap: 13vw;
    overflow: hidden;
}
.smart-contract-audit-content-wrapper-mob {
    width: 90vw;
    display: flex;
    gap: 8vw;
    font-size: 3vw;
    flex-direction: column;
    align-items: center;
    color: #A8B7CE;
    transform: translate(0, -10%);
}


.smart-contract-audit-content-wrapper-mob>img {
    width: 90vw;
}
.smart-contract-audit-content-wrapper-mob>div{
    display: flex;
    flex-direction: column;
    gap:2vw
}
/* constants */

@media only screen and (max-width:500px) {
    .mob-hidden {
        display: none;
    }
}

@media only screen and (min-width:500px) {
    .non-mob-hidden {
        display: none;
    }
}